/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { Typography } from "@material-ui/core";
import axios from "axios";
import { BASE_URI, MASTER_DEALERID } from "../shared/Constants";
import { Finance } from "@red-build/leadcentre";
import { ContactUs as Form } from "@red-build/leadcentre";
import { DealerContext } from "../shared/contexts/DealerContext";
import Divider from '@mui/material/Divider';

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: '79px',
  },
  imageHolder: {
    width: "100%",
    height: 'calc(100vh - 105px)',
    backgroundSize: "cover",
    position: 'relative',
    backgroundPosition: "center",
    "@media (max-width: 959px)": {
      height: 'calc(60vh - 105px)',
    },
  },
  imageHolderText: {
    fontSize: "20px",
    fontWeight: "bold",
    padding: "10px 50px",
    color: "#209287",
    textTransform: "uppercase",
    background: '#083246',
    border: "#209287 2px solid",
    borderRadius: '50px',
    position: 'absolute',
    bottom: '40px',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    "@media (max-width: 959px)": {
      padding: "10px 20px",
      fontSize: "14px",
    },
  },
  imageHolderTextWrap: {
    fontWeight: "bold",
    color: "white",
    textTransform: "uppercase",
  },
  bold: {
    color: "#209287",
    fontWeight: "bold",
    fontSize: "6rem",
  },
  accent: {
    color: "#209287",
  },
  tabHolder: {
    background: "#b6b6b6",
    width: "100%",
    padding: "0px 0px",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    marginBottom: "40px",
    transition: "0.5s all ease-in",
    transform: ({ tabChangeAmount }) => `translate(${tabChangeAmount}%,0%)`,
    "@media (max-width: 959px)": {
      justifyContent: "unset",
    },
    "@media (max-width: 1100px)": {},
  },
  tabNav: {
    position: "absolute",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "0px 20px",
    transform: "translate(0px , -50%)",
    top: "32%",
    zIndex: "2",
    color: "#083246",
  },
  tab: {
    cursor: "pointer",
    borderBottom: "4px #b81b2200 solid",
    background: "#b6b6b6",
    display: "flex",
    alignItems: "center",
    padding: "20px 40px",
    justifyContent: "center",
    fontSize: "1.2rem",
    "@media (max-width: 425px)": {
      minWidth: "calc(100% / 2)",
      padding: "15px 30px",
      fontSize: "1.2rem",
    },
    "@media (min-width: 426px) and (max-width: 959px)": {
      minWidth: "calc(100% / 2)",
      padding: "15px 30px",
      fontSize: "1.2rem",
    },
  },
  tabActive: {
    cursor: "pointer",
    borderBottom: "4px #083246 solid",
    background: "#b6b6b6",
    display: "flex",
    alignItems: "center",
    padding: "20px 40px",
    justifyContent: "center",
    fontSize: "1.2rem",
    transform: "scale(1.05)",
    fontWeight: "bolder",
    "@media (max-width: 425px)": {
      minWidth: "calc(100% / 2)",
      padding: "15px 30px",
      fontSize: "1.2rem",
    },
    "@media (min-width: 426px) and (max-width: 959px)": {
      minWidth: "calc(100% / 2)",
      padding: "15px 30px",
      fontSize: "1.2rem",
    },
  },
  tabModel: {
    display: "none",
    "@media (max-width: 959px)": {
      background: "grey",
      position: "absolute",
      height: "100%",
      display: "flex",
      alignItems: "center",
      minWidth: "calc(100% / 3)",
      padding: "15px 30px",
      fontSize: "1.2rem",
    },
  },
  galleryHolder: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    margin: '0px auto',
    position: 'relative',
    top: '-82px',
    marginBottom: '-82px',
    "@media (max-width: 959px)": {
      top: '-30px',
    },
  },
  galleryHolderOutter: {
    textAlign: 'center',
    padding: "40px 80px 0px 80px",
    width: "100%",
    background: 'white',
    "@media (max-width: 959px)": {
      width: "100%",
      padding: "40px 0px 40px 0px",
    },
  },
  galleryHolderP: {
    display: "flex",
    flexDirection: 'row',
    overflowX: 'scroll',
    justifyContent: 'start',
  },
  galleryHolderPOutter: {
    textAlign: 'center',
    padding: "0px 50px 50px 50px",
    width: "100%",
    background: 'white',
    justifyContent: 'center',
    "@media (max-width: 959px)": {
      width: "100%",
      padding: "0px 40px",
    },
  },
  brochureHolder: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "1170px",
    width: "100%",
    justifyContent: "center",
    padding: "0px 15px",
  },
  FeatureHolder: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "1170px",
    width: "100%",
    padding: "0px 15px",
  },
  HighlightsHolder: {
    display: "flex",
    flexWrap: "wrap",
    background: 'white',
    flexDirection: 'column',
    width: "100%",
    "@media (max-width: 959px)": {
      width: "100%",
    },
  },
  HighlightsHolderGroup: {
    width: "100% ",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    "@media (max-width: 959px)": {
      maxWidth: "unset",
    },
    "&:nth-child(3)": {
      maxWidth: "unset",
    },
  },
  divider: {
    margin: "10px 10px",
    background: "#209287",
    width: "100px",
    minHeight: "4px",
  },
  HighlightsHolderGroupTextInner: {
    padding: '100px 50px',
    background: 'linear-gradient(90deg, #12384c, transparent)',
    "@media (max-width: 959px)": {
      padding: '20px 20px',
    },
  },
  HighlightsHolderGroupTextInnerRight: {
    padding: '100px 50px',
    display: 'flex',
    justifyContent: 'end',
    background: 'linear-gradient(270deg, #12384c, transparent)',
    "@media (max-width: 959px)": {
      padding: '20px 20px',
    },
  },
  HighlightsHolderGroupText: {
    color: 'white',
    width: "40%",
    "@media (max-width: 959px)": {
      width: "100%",
    },
  },
  galleryImg: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: "calc(100% / 3 - 20px)",
    minWidth: "calc(100% / 3 - 20px)",
    margin: "10px",
    cursor: "pointer",
    border: '5px solid white',
    lineHeight: '0px',
    boxShadow: '0px 0px 7px 5px #0000004a',
    "&:hover": {
      transform: "scale(1.02)",
    },
    "@media (max-width: 959px)": {
      "&:nth-child(4)": {
        maxWidth: "calc(100% / 2 - 10px)",
      },
      "&:nth-child(5)": {
        maxWidth: "calc(100% / 2 - 10px)",
      },
      maxWidth: "calc(100% / 3 - 10px)",
      margin: "5px",
    },
  },
  titleCardTop: {
    margin: "10px 0px",
  },
  titleTop: {
    margin: "10px 0px",
    marginBottom: '40px'
  },
  featuresCard: {
    maxWidth: "calc(100% / 3 - 20px)",
    minWidth: "calc(100% / 3 - 20px)",
    boxShadow: "0 0 5px 0 #083246 ",
    margin: "10px",
    padding: "20px",
    borderRadius: "3px",
    "@media (max-width: 425px)": {
      maxWidth: "unset",
      width: "100%",
    },
    "@media (max-width: 959px)": {
      maxWidth: "calc(100% / 2 - 20px)",
      width: "100%",
    },
  },
  featuresCardTitle: {
    fontWeight: "bold",
  },
  cta: {
    padding: "10px 40px",
    border: "none",
    cursor: "pointer",
    background: "#083246",
    marginRight: "10px",
    marginTop: "10px",
    color: "white",
    borderRadius: "1rem",
    "&:hover": {
      boxShadow: "0 0 5px 0 #4d0b0e ",
    },
  },
  ctaGrey: {
    padding: "10px 40px",
    border: "none",
    cursor: "pointer",
    background: "#083246",
    color: 'white',
    marginTop: "10px",
    borderRadius: "1rem",
    "&:hover": {
      boxShadow: "0 0 5px 0 #4d0b0e ",
    },
  },
  link: {
    color: "white",
  },
  PricingCard: {
    minWidth: "calc(100% / 4 - 30px)",
    maxWidth: "calc(100% / 4 - 30px)",
    width: "100%",
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
    borderRadius: '15px',
    padding: '20px 20px 0px 20px',
    margin: "15px",
    "@media (max-width: 570px)": {
      minWidth: "calc(100% / 1 - 30px)",
      maxWidth: "calc(100% / 1 - 30px)",
      width: "100%",
    },
    "@media (min-width: 801px) and (max-width: 1080px)": {
      minWidth: "calc(100% / 3 - 30px)",
      maxWidth: "calc(100% / 3 - 30px)",
      width: "100%",
    },
    "@media (min-width: 571px) and (max-width: 800px)": {
      minWidth: "calc(100% / 2 - 30px)",
      maxWidth: "calc(100% / 2 - 30px)",
      width: "100%",
    },
  },
  PricingCardTextHolder: {
    padding: "20px 0px",
    color: "#083246",
    textAlign: 'left'
  },
  PricingCardText: {
    fontSize: "19px",
    color: "#083246",
  },
  PricingCardImg: {
  },
  tabHolderOutter: {
    overflow: "hidden",
    width: "100%",
    position: "relative",
  },
  tabNavBack: {
    position: "absolute",
    top: "18px",
    left: "5px",
    "@media (min-width: 959px)": {
      display: "none",
    },
  },
  tabNavNext: {
    position: "absolute",
    right: "5px",
    top: "18px",
    "@media (min-width: 959px)": {
      display: "none",
    },
  },
  ctaHolder: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px auto",
    padding: "20px 50px",
    "@media (max-width: 959px)": {
      flexDirection: "column",
      padding: "0px 50px",
    },
  },
  ctaHolderButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: 'wrap',
    margin: "10px auto",
  },
  ctaHolderOutter: {
    background: "white",
    width: "100%",
    "@media (max-width: 959px)": {
      width: "100%",
    },
  },
  From: {
    color: "#083246",
    fontSize: "30px",
  },
  FromTitle: {
    color: "#083246",
    fontSize: '45px',
    margin: '0px',
    "@media (max-width: 500px)": {
      padding: "0px 0px",
      fontSize: '50px',
    },
    "@media (min-width:501px) and (max-width: 768px)": {
      padding: "0px 0px",
      fontSize: '50px',
    },
  },
  priceAccent: {
    color: "#209287",
    fontSize: "40px",
    margin: '0px'
  },
  ctaIntro: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: "20px",
    fontWeight: "bold",
    width: '100%',
    "@media (max-width: 959px)": {
      width: '100%',
    },
  },
  ctaIntroBtnHolder: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    "@media (max-width: 959px)": {
      width: '100%',
    },
  },
  ctaTop: {
    padding: "10px 40px",
    border: "none",
    cursor: "pointer",
    background: "#083246",
    margin: "10px",
    borderRadius: "1rem",
    color: "white",
    "&:hover": {
      boxShadow: "0 0 5px 0 #4d0b0e ",
    },
    "@media (max-width: 959px)": {
      width: 'calc(100% / 2 - 20px)',
      padding: "10px",
    },
  },
  contactPopUpHolder: {
    position: "fixed",
    background: "#06423c8c",
    width: "100%",
    height: "100vh",
    top: "0px",
    zIndex: "5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contactPopUpHolderInner: {
    background: 'white',
    padding: '0px 40px 40px 40px',
    width: "40%",
    "@media (max-width: 1024px)": {
      width: "90%",
      maxHeight: '78%',
      overflowX: 'auto',
    },
  },
  Close: {
    color: "red",
    textAlign: "right",
    padding: "15px 15px",
    fontSize: "20px",
    cursor: "pointer"
  },
  text: {
    margin: '10px 0px',
    display: 'block',
  },
  colorBlockHolder: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '0 auto',
    flexWrap: 'wrap',
    marginTop: '20px',
    "@media (max-width: 1024px)": {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: "10px",
    },
  },
  colorBlockImage: {
    width: '40%',
    margin: '0 auto',
    "@media (max-width: 900px)": {
      width: "90%",
    },
  },
  colorBlockOutter: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% / 6 - 20px)',
    // "@media (max-width: 425px)": {
    //   width: 'calc(100% / 2 - 20px)',
    //   margin: '10px'
    // },
    // "@media (min-width: 426px) and (max-width: 1024px)": {
    //   width: 'calc(100% / 3 - 20px)',
    //   margin: '10px'
    // },
    "@media (max-width: 1024px)": {
      width: '50%',
    },
  },
  colorBlock: {
    minHeight: '20px',
    borderRadius: '10px',
    margin: '10px',
    padding: "20px",
  },
  middleTile: {
    fontSize: "1.75rem",
    textAlign: 'center',
    paddingTop: '40px',
    color: "#003145"
  },
  middleTilePopUp: {
    fontSize: "2.5rem",
    textAlign: 'center',
    color: "#003145"
  },
  deviderContainer: {
    marginTop: '25px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '3rem',
  },
  newMiddleDivider: {
    background: "#53aca2",
    width: '100px',
    height: '5px',
    margin: 'auto',
  },
  newSecondaryColor: {
    color: "#53aca2",
  },
  rootInner: {
    maxWidth: '1360px',
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  introDesc: {
    background: 'white',
    width: '50%',
    // padding: '30px 0px',
    textAlign: 'justify',
    fontSize: '17px',
    "@media (max-width: 1024px)": {
      width: '100%',
    },

  },
  dividerFull: {
    background: "#20928770",
    padding: '0px 50px',
    width: "100%",
    minHeight: "3px",
  },
  intro: {
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0rem 1rem 2rem 1rem'
  }
}));

const DetailedVehicle = () => {
  const { newVehiclesList } = useContext(VehicleContext);
  const [activeVehicles, setActiveVehicles] = useState();
  const [activeTab, setActiveTab] = useState("Highlights");
  const tabChangeAmount = 0
  const [activeGalleryIndex, setActiveGalleryIndex] = useState(0);
  const [pricingList, setPricingList] = useState([]);
  const [activeVariant, setActiveVariant] = useState([]);
  const [colours, setColours] = useState([]);
  const [coloursIndex, setColoursIndex] = useState(0);
  const [contactPopUp, setContactPopUp] = useState(false);
  const [finanacePopUp, setFinanacePopUp] = useState(false);
  const { globalDealer, dealerList } = useContext(DealerContext);
  const [activeTitle, setActiveTitle] = useState();
  const paramss = useParams();
  const classes = useStyles({ tabChangeAmount });

  useEffect(() => {
    setActiveTab("Highlights");
    axios({
      method: "GET",
      url: `${BASE_URI}/DealerModelInformation/GetForDealerModel/${paramss?.stockId}`,
    })
      .then((response) => {
        setActiveVehicles(response.data);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });

    let params = {
      makeId: 8104,
      dealerModelId: paramss.stockId,
    };

    axios({
      method: "GET",
      url: `${BASE_URI}/dealervariants`,
      params,
    })
      .then((response) => {
        setPricingList(response.data.list);
        setActiveVariant(response.data.list[0])
        axios({
          method: "GET",
          url: `${BASE_URI}/colours/exteriors?dealerVariantId=${response.data.list[0].id}`,
        })
          .then((response) => {
            if (response.data.list.length > 0) {
              setColours(response.data.list)
            } else {
              setColours([])
            }
          })
          .catch((error) => {
            if (axios.isCancel(error)) return;
          });
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });
  }, [paramss]);

  const handleContactPopUp = () => {
    setContactPopUp(true);
  };

  useEffect(() => {
    setActiveTitle(newVehiclesList.filter((v) => v.id == paramss?.stockId)[0]);
  }, [paramss, newVehiclesList]);

  var formatter = new Intl.NumberFormat("af", {
    style: "currency",
    currency: "ZAR",
    minimumFractionDigits: 2,
  });

  function openPDF(pdf) {
    window.open(pdf);
    return false;
  }

  function calculateMonthly(data) {
    let deposit = data?.priceRange * (10 / 100);
    let totalPrice = data?.priceRange;
    let initFee = 0;
    let principal = totalPrice - deposit + initFee;
    let balloonPerc = 10;
    let balloonAmt = (totalPrice * balloonPerc) / 100;
    let interestRate = 9
    let interestPM = interestRate / 100 / 12;
    let repaymentPeriod = 72;
    let days = 1;
    let v = 1 / (1 + interestPM);
    let d = 1 - v;
    let fp = principal * Math.pow(1 + interestPM, days / (365 / 12).toFixed());
    let monthly = 0;

    if (parseInt(balloonAmt) > 0) {
      let comp = (1 - Math.pow(v, repaymentPeriod - 1)) / d;
      monthly = (fp - balloonAmt * Math.pow(v, repaymentPeriod - 1)) / comp;
    } else {
      let comp = (1 - Math.pow(v, repaymentPeriod)) / d;
      monthly = fp / comp;
    }

    return round(monthly) + initFee;
  }

  function round(x) {
    return Math.round(x * 100) / 100;
  }

  return (
    <div className={classes.root}>
      <div className={classes.rootInner}>
        <div
          className={classes.imageHolder}
          style={{ backgroundImage: `url(${activeVehicles?.contentImages[0]?.image?.imageUrl})` }}
        >
          <button
            className={classes.imageHolderText}
            onClick={() => {
              handleContactPopUp();
            }}
          >
            Book A Test Drive
          </button>
        </div>
        {contactPopUp && (
          <div className={classes.contactPopUpHolder}>
            <div className={classes.contactPopUpHolderInner}>
              <div
                className={classes.Close}
                onClick={() => {
                  setContactPopUp(false);
                }}
              >
                X
              </div>
              <div className={classes.middleNew}>
                <Typography variant="h1" className={classes.middleTilePopUp}>
                  <strong><span className={classes.newSecondaryColor}>Enquire</span> Now</strong>
                </Typography>
                <div className={classes.deviderContainer}><Divider className={classes.newMiddleDivider} /></div>
              </div>
              <Form
                dealers={dealerList}
                dealerType="multi_dealer"
                dealerId={globalDealer?.dealerId}
                base_uri={BASE_URI}
                leadTypeId={1}
                VariantId={activeVariant?.variantId}
                dealerName={globalDealer?.name?.replace('Master', ' ')}
            />
            </div>
          </div>
        )}
        {finanacePopUp && (
          <div className={classes.contactPopUpHolder}>
            <div className={classes.contactPopUpHolderInner}>
              <div
                className={classes.Close}
                onClick={() => {
                  setFinanacePopUp(false);
                }}
              >
                X
              </div>
              <div className={classes.middleNew}>
                <Typography variant="h1" className={classes.middleTilePopUp}>
                  <strong><span className={classes.newSecondaryColor}>Apply</span> For Finance</strong>
                </Typography>
                <div className={classes.deviderContainer}><Divider className={classes.newMiddleDivider} /></div>
              </div>
              <Finance
                masterDealerId={MASTER_DEALERID}
                dealers={dealerList}
                base_uri={BASE_URI}
                dealerId={MASTER_DEALERID}
                dealerType={globalDealer?.dealerId !== 584 ? "single_dealer" : "multi_dealer"}
                variantId={activeVariant?.variantId}
                leadTypeId={12}
              />
            </div>
          </div>
        )}
        <div className={classes.ctaHolderOutter}>
          <div className={classes.ctaHolder}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <h3 className={classes.FromTitle}><strong><span className={classes.newSecondaryColor}>{activeTitle?.make}</span> {activeTitle?.title}</strong></h3>
              <div className={classes.ctaIntro}>
                <div className={classes.From}>
                  <stong>From only</stong>
                  <h3 className={classes.priceAccent}>
                    <strong>
                      {formatter.format(activeTitle?.price)}
                    </strong>
                  </h3>
                </div>
              </div>
            </div>
            <div className={classes.ctaIntroBtnHolder}>
              <button
                className={classes.ctaTop}
                onClick={() => { openPDF(activeVehicles.brochures[0].document.filePath) }}
              >
                Brochure
              </button>
              <button
                className={classes.ctaTop}
                onClick={() => {
                  handleContactPopUp();
                }}
              >
                Enquire Now
              </button>
              {/* <button
                className={classes.ctaTop}
                onClick={() => {
                  handleContactPopUp();
                }}
              >
                Book A Test Drive
              </button> */}
            </div>
          </div>
        </div>
        <div className={classes.intro}>
          <Divider className={classes.dividerFull}></Divider>
          <div className={classes.middleNew}>
            <Typography variant="h1" className={classes.middleTile}>
              <strong>{activeVehicles?.introductionTitle}</strong>
            </Typography>
            <div className={classes.deviderContainer}><Divider className={classes.newMiddleDivider} /></div>
          </div>
          <Typography variant="body1" className={classes.introDesc}>
            {activeVehicles?.introductionDesc}
          </Typography>
        </div>
        <div className={classes.HighlightsHolder}>
          <div className={classes.HighlightsHolderGroup} style={{ backgroundImage: `url(${activeVehicles?.contentImages[1]?.image?.imageUrl})` }}>
            <div className={classes.HighlightsHolderGroupTextInner}>
              <div className={classes.HighlightsHolderGroupText}>
                <Typography variant="h4" className={classes.titleCardTop}>
                  {activeVehicles?.contentTitle1}
                </Typography>
                <Divider className={classes.divider}></Divider>
                <Typography variant="body" className={classes.text} style={{ textAlign: 'justify' }}>
                  {activeVehicles?.contentDesc1}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.HighlightsHolderGroup} style={{ backgroundImage: `url(${activeVehicles?.contentImages[2]?.image?.imageUrl})` }}>
            <div className={classes.HighlightsHolderGroupTextInnerRight}>
              <div className={classes.HighlightsHolderGroupText}>
                <Typography variant="h4" className={classes.titleCardTop}>
                  {activeVehicles?.contentTitle2}
                </Typography>
                <Divider className={classes.divider}></Divider>
                <Typography variant="body" className={classes.text} style={{ textAlign: 'justify' }}>
                  {activeVehicles?.contentDesc2}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.HighlightsHolderGroup} style={{ backgroundImage: `url(${activeVehicles?.contentImages[3]?.image?.imageUrl})` }}>
            <div className={classes.HighlightsHolderGroupTextInner}>
              <div className={classes.HighlightsHolderGroupText}>
                <Typography variant="h4" className={classes.titleCardTop}>
                  {activeVehicles?.contentTitle3}
                </Typography>
                <Divider className={classes.divider}></Divider>
                <Typography variant="body" className={classes.text} style={{ textAlign: 'justify' }}>
                  {activeVehicles?.contentDesc3}
                </Typography>
              </div>
            </div>
          </div>
        </div>
        {activeTab == "Features" && (
          <div className={classes.FeatureHolder}>
            {activeVehicles?.features.map((f) => {
              return (
                <div className={classes.featuresCard}>
                  <div className={classes.featuresCardTitle}>{f.title}</div>
                  <div> {f.description}</div>
                </div>
              );
            })}
          </div>
        )}
        <div className={classes.galleryHolderOutter}>
          <div className={classes.middleNew}>
            <Typography variant="h1" className={classes.middleTile}>
              <strong><span className={classes.newSecondaryColor}>Gallery</span> Images</strong>
            </Typography>
            <div className={classes.deviderContainer}><Divider className={classes.newMiddleDivider} /></div>
          </div>
          <img alt="" src={activeVehicles?.galleryImages[activeGalleryIndex]?.image?.imageUrl} width="100%" />
          <div className={classes.galleryHolder}>
            {/* eslint-disable-next-line array-callback-return */}
            {activeVehicles?.galleryImages.map((v, index) => {
              // if (index < 5) // We do have support for 5 gallery images, but I would like to wait until it's a problem
              if (index < 3)
                return (
                  <div className={classes.galleryImg}>
                    <img
                      alt=""
                      src={v?.image?.imageUrl}
                      width="100%"
                      onClick={() => {
                        setActiveGalleryIndex(index);
                      }}
                    />
                  </div>
                );
            })}
          </div>
        </div>
        {/* <div className={classes.galleryHolderOutter}>
          <div className={classes.middleNew}>
            <Typography variant="h1" className={classes.middleTile}>
              <strong><span className={classes.newSecondaryColor}>Available</span> Colours</strong>
            </Typography>
            <div className={classes.deviderContainer}><Divider className={classes.newMiddleDivider} /></div>
          </div>
          {colours?.length > 1 &&
            <div className={classes.colorBlockImage} > <img alt="" src={colours[coloursIndex]?.image} width="100%" /></div>
          }
          <div className={classes.colorBlockHolder}>
            {colours?.map((c, index) => {
              return (
                <div className={colours?.length > 1 ? classes.colorBlockOutter : classes.colorBlockImage} onClick={() => { setColoursIndex(index) }}>
                  <img alt="" src={c?.image} width="100%" />
                  <p> {c.title}</p>
                </div>
              )
            })}
          </div>
        </div> */}
        <div className={classes.galleryHolderPOutter}>
          <div className={classes.middleNew}>
            <Typography variant="h1" className={classes.middleTile}>
              <strong><span className={classes.newSecondaryColor}>Range</span> Models</strong>
            </Typography>
            <div className={classes.deviderContainer}><Divider className={classes.newMiddleDivider} /></div>
          </div>
          <div className={classes.galleryHolderP} style={{ justifyContent: pricingList.length < 4 && 'center' }}>
            {pricingList.map((v, index) => {
              return (
                <div className={classes.PricingCard}>
                  <img
                    alt=""
                    src={v.image}
                    width="100%"
                    className={classes.PricingCardImg}
                  />
                  <div className={classes.PricingCardTextHolder}>
                    <div className={classes.PricingCardText}>{v.variantName}</div>
                    <div>{formatter.format(v.priceRange)} or {formatter.format(calculateMonthly(v))} pm</div>
                    <div className={classes.ctaHolderButton}>
                      {/* <button
                      className={classes.ctaGrey}
                      onClick={() => {
                        setFinanacePopUp(v);
                      }}
                    >
                      Finance
                    </button> */}
                      <button className={classes.ctaGrey} onClick={() => { handleContactPopUp() }}>Enquire Now</button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

    </div>
  );
};

export default DetailedVehicle;
